@import '~antd/dist/antd.css';

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #09d3ac;
}

.ant-table-filter-dropdown-link {
	padding: 0 8px;
}

.table-row-new {
	background-color: #00b47033;
	color: #00b470;
}

.table-row-deleted {
	background-color: #f12e4b33;
	color: #f12e4b;
	text-decoration: line-through;
}

.table-row-deleted a {
	color: inherit;
	pointer-events: none;
	cursor: default;
}

.table-row-changed {
	background-color: #ffa80133;
}
